import * as echarts from 'echarts'

const axisLabel = {
  fontSize: 8,
  interval: 0
}
const grid = { // 图例位置
  y: '10%',
  left: '1%',
  right: '3%',
  bottom: '3%',
  height: '90%',
  containLabel: true
}

export const lineChart = (box, dayData) => {
  const myChart = echarts.init(document.getElementById(box))
  myChart.setOption({
    title: {
      text: `环比昨日 ▲${dayData.ringRatio}%`,
      x: 'right',
      y: '-4px',
      textStyle: {
        color: 'rgba(255,255,255)',
        fontWeight: 'normal',
        fontSize: 12
      }
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        lineStyle: {
          type: 'dashed' // 鼠标移入时的指示线 虚线/实线
        },
        label: {
          backgroundColor: '#6a7985'
        }
      }
    },
    xAxis: {
      type: 'category',
      data: dayData.first.xAxis,
      axisLine: {
        show: false, // x轴刻度线的显示隐藏
        lineStyle: {
          color: 'rgba(255,255,255,.7)'
        }
      },
      axisTick: {
        show: false // X轴的刻度线分尺的显示隐藏
      }
    },
    yAxis: {
      type: 'value',
      show: false // y轴刻度线的显示隐藏
    },
    axisLabel: axisLabel,
    grid: grid, // 图例位置
    series: [
      // 第一天
      {
        name: '今天', // 鼠标移入时显示数据的标题
        data: dayData.first.series, // 图例数据
        showSymbol: false, // 线条折点的显示隐藏
        type: 'line',
        smooth: true, // 是否开启将对折线改为曲线
        itemStyle: {
          normal: {
            lineStyle: {
              color: 'rgba(217, 162, 255,.8)' // 线条颜色
            }
          }
        },
        lineStyle: {
          normal: {
            width: 3 // 线条宽度
          }
        }
      }
      // ,
      // // 第二天
      // {
      //   name: '昨天',
      //   data: [0.04, 0.06],
      //   showSymbol: false, // // 线条折点的显示隐藏
      //   type: 'line',
      //   smooth: true, // 是否开启将对折线改为曲线
      //   itemStyle: {
      //     normal: {
      //       lineStyle: {
      //         color: 'rgba(255,255,255,.2)' // 线条颜色
      //       }
      //     }
      //   },
      //   lineStyle: {
      //     normal: {
      //       width: 3 // 线条宽度
      //     }
      //   }
      // }
    ]
  })
}

export const tree = (box, weekData) => {
  // 基于准备好的dom，初始化echarts实例
  const myChart = echarts.init(document.getElementById(box))
  // 绘制图表
  myChart.setOption({
    title: {
      text: '本周收益情况',
      textAlign: 'center',
      x: 'right',
      y: '-3px',
      textStyle: {
        color: 'rgba(255,255,255)',
        fontWeight: 'normal',
        fontSize: 12
      }
    },
    grid: {
      y: '10%',
      left: 'left',
      // right: '3%',
      bottom: '3%',
      height: '90%',
      containLabel: true
    },
    // color: 'rgba(255,255,255,.2)',
    xAxis: {
      type: 'category',
      data: weekData.xAxis,
      axisLine: {
        lineStyle: {
          color: 'rgba(255,255,255,.7)'
        }
      },
      axisLabel: axisLabel,
      axisTick: {
        show: false
      }
    },
    yAxis: {
      type: 'value',
      show: false
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        label: {
          backgroundColor: '#6a7985'
        }
      }
    },
    series: [
      {
        data: weekData.series,
        barWidth: '50%', // 柱图宽度
        barGap: '10%', // 条形间隙
        type: 'bar',
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: 'rgba(255,255,255,.7)' },
            { offset: 1, color: 'rgba(73,173,252,.7)' }
          ])
        }
      }
    ]
  })
}

export const area = (box, monthData) => {
  const myChart = echarts.init(document.getElementById(box))
  myChart.setOption({
    title: {
      text: `环比上月 ▲${monthData.ringRatio}%`,
      textAlign: 'center',
      x: 'right',
      y: '-3px',
      textStyle: {
        color: 'rgba(255,255,255)',
        fontWeight: 'normal',
        fontSize: 12
      }
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      axisTick: {
        show: false
      },
      axisLine: {
        show: false,
        lineStyle: {
          color: 'rgba(255,255,255,.7)'
        }
      },
      axisLabel: axisLabel,
      data: monthData.xAxis
    },
    yAxis: {
      type: 'value',
      show: false
    },
    grid: {
      y: '10%',
      left: 'left',
      // right: '3%',
      bottom: '3%',
      height: '90%',
      containLabel: true
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'line',
        lineStyle: {
          color: '#fff',
          type: 'dashed' // 鼠标移入时的指示线 虚线/实线
        },
        label: {
          backgroundColor: 'rgba(255,255,255,.7)'
        }
      }
    },
    series: [
      {
        data: monthData.series,
        type: 'line',
        barGap: '10%', // 条形间
        areaStyle: {},
        showSymbol: false,
        itemStyle: {
          normal: {
            lineStyle: {
              color: '#05CA77'
            },
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: 'rgba(255,255,255,.2)' },
              { offset: 1, color: 'rgba(29,171,113,.7)' }
            ])
          }
        },
        smooth: true
      }
    ]
  })
}

export const ring = (box, yearData) => {
  const myChart = echarts.init(document.getElementById(box))
  myChart.setOption({
    title: {
      text: yearData.yearMount,
      // subtext: '年度总收入(万元)',
      x: 'left',
      y: 'left',
      itemGap: -5,
      textStyle: {
        fontSize: 30
      },
      subtextStyle: {
        color: '#49ADFC',
        fontSize: 20,
        y: 10
      }
    },
    color: ['#11AD70', '#7865F2', '#4D86F9', '#F2573E', '#9A60B4', '#506EC7', '#E97CCD', '#90CC77', '#73BFDD', '#FB834F', '#FAC857'],
    // tooltip: {
    //   trigger: 'item',
    //   formatter: '{a} <br/>{b} : {c} <br/>({d}%)'
    // },
    legend: {
      top: '5%',
      right: 5,
      orient: 'vertical'
    },
    series: [
      {
        name: '具体数据',
        type: 'pie',
        radius: ['45%', '80%'],
        label: {
          formatter: '{d}',
          show: true,
          normal: {
            position: 'center',
            show: false,
            formatter: '{b}\n{c}'
          },
          // position: 'center',
          // formatter: function () {
          //   return '   年度总收入 \r\n 100000'
          // },
          textStyle: {
            fontSize: 24,
            align: 'center'
          }
        },
        labelLine: {
          show: true // 数据指示线
        },
        center: ['40%', '50%'], // 图形位置
        // avoidLabelOverlap: false,
        // hoverAnimation: false, // 鼠标移入时显示数据
        itemStyle: {
          borderRadius: 13,
          borderColor: '#fff',
          borderWidth: 3 // 区域块间隙
        },
        emphasis: { // 移入时特效
          label: {
            show: true,
            fontSize: 30,
            align: 'center',
            // formatter: '{d}%',
            fontWeight: 'bold'
          }
        },
        data: yearData.data
      }
    ]
  })
}
