import { postBodyRequest, getRequest, exportRequest } from '@/utils/axios'

export function PayListPage (params) {
  return postBodyRequest('/pay/sandpay/page', params)
}

export function statistics (params) {
  return getRequest('/pay/sandpay/income/statistics', params)
}

export function department (params) {
  return getRequest('/pay/sandpay/department/statistics', params)
}

export function pageDetail (params) {
  return getRequest(`/trans/orderpay/getSandPayDetail?${params}`)
}

export function sandPayExport (params, fileName) {
  return exportRequest('/trans/data/vehicle/quota/sandPay/export', params, fileName)
}
