<style lang="less" scoped>
@import './index.less';
</style>
<template>
  <div>
    <div class="title-data">
      <div class="day">
        <p>今日总收益(元)</p>
        <div class="day-content">
          <RedoOutlined class="reload-btn" @click="reloadData(1)" />
          <div class="day-left">
            <p class="total-money">{{dayData.totalAmount === null ? '0' : dayData.totalAmount}}</p>
            <a-button class="num" size="small" shape="round" type="link">共{{dayData.tradeCount}}笔</a-button>
          </div>
          <!-- echarts图表 -->
          <div class="day-right" id="day"></div>
        </div>
      </div>
      <div class="week">
        <p>本周总收益(元)</p>
        <div class="week-content">
          <RedoOutlined class="reload-btn" @click="reloadData(2)" />
          <div class="week-left">
            <p class="total-money">{{weekData.totalAmount === null ? '0' : weekData.totalAmount}}</p>
            <a-button class="num" size="small" shape="round" type="link">共{{weekData.tradeCount}}笔</a-button>
          </div>
          <!-- echarts图表 -->
          <div class="week-right" id="week"></div>
        </div>
      </div>
      <div class="months">
        <p>本月总收益(元)</p>
        <div class="months-content">
          <RedoOutlined class="reload-btn" @click="reloadData(3)" />
          <div class="months-left">
            <p class="total-money">{{monthData.totalAmount === null ? '0' : monthData.totalAmount}}</p>
            <a-button class="num" size="small" shape="round" type="link">共{{monthData.tradeCount}}笔</a-button>
          </div>
          <!-- echarts图表 -->
          <div class="months-right" id="months"></div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="day-content">
        <a-tabs v-model:activeKey="activeKey">
          <a-tab-pane key="1">
            <template #tab>
              <div style="display:flex">
                <a-form layout="inline">
                  <a-form-item label="">
                    <a-input v-model:value="search.amt" placeholder="金额"/>
                  </a-form-item>
                  <a-form-item label="">
                    <a-range-picker style="width:200px" :show-time="{ format: 'HH:mm' }" format="YYYY-MM-DD HH:mm:ss" :placeholder="['开始时间', '截止时间']" @change="searchTime" placeholder="时间" />
                  </a-form-item>
                  <a-form-item label="">
                    <a-input v-model:value="search.orderCode" placeholder="流水号" />
                  </a-form-item>
                  <a-form-item label="">
                    <a-input v-model:value="search.bankserial" placeholder="交易单号" />
                  </a-form-item>
                </a-form>
                <a-button type="primary" :disabled="loading" @click="pageList(1)">查询</a-button>
                <a-button @click="sandPayExportData">导出支付</a-button>
              </div>
            </template>
            <a-table :dataSource="dataSource" :columns="columns" :row-key="record => record.fosId" :loading="loading" :pagination="pagination" @change="handleTableChange">
              <template #fosId="{ record }">
                <div class="editable-row-operations">
                  <span>
                    {{record.fosId === null ? record.frId : record.fosId}}
                  </span>
                </div>
              </template>
              <template #edit="{ record }">
                <div class="editable-row-operations">
                  <span>
                    <a @click="look(record)">查看详情</a>
                  </span>
                </div>
              </template>
            </a-table>
          </a-tab-pane>
        </a-tabs>
      </div>
      <div class="year-content">
        <p style="display:flex;justify-content: space-between;">
          <span style="font-size:18px;font-weight:bold;">年度部门收款统计</span>
          <a-select v-model:value="value" :options="options" placeholder="当天" style="width: 120px" @change="handleChange" />
        </p>
        <div id="year"></div>
        <div class="department">
          <div style="margin-bottom:10px;width:50%" v-for="item in departmentData" :key="item.orgId">
            <h3 style="margin:0">{{item.orgName}}：<span class="number" :style="{left:item.ratio * 100 + '%'}">{{(item.ratio * 100).toFixed(2)}}%</span></h3>
            <div class="progress">
              <a-progress :stroke-color="{'0%': '#13AC70','100%': '#ABEACF'}" trailColor="#eee" :showInfo="false" :percent="item.ratio * 100" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <a-modal v-model:visible="detailStatus" :width="700" title="查看详情" footer="">
      <div v-if="detailStatus" style="display:flex;flex-wrap: wrap;">
        <div style="width:50%;margin-bottom:15px"> {{dataDetail.financeReconciliationInfo === null ? '订单号' : '账单号'}}：{{ dataDetail.financeReconciliationInfo ? dataDetail.financeReconciliationInfo.id : dataDetail.orderInfo.orderId}}</div>
        <div style="width:50%;margin-bottom:15px"> 付款账号：{{dataDetail.acclogonNo}}</div>
        <div style="width:50%;margin-bottom:15px" v-if="dataDetail.orderInfo"> 结算方式：{{dataDetail.orderInfo.orderSettlement.label}}</div>
        <div style="margin-bottom:15px" v-show="dataDetail.vehicleInfoList !== null">
          订单车辆：
          <a-tag color="blue" v-for="item in dataDetail.vehicleInfoList" :key="item.orderVehicleId">{{item.brand + item.model + '：' + item.vinNo}}</a-tag>
        </div>
        <div v-if="dataDetail.financeReconciliationInfo">发起人：{{dataDetail.financeReconciliationInfo.sponsor}}</div>
      </div>
      <a-divider style="height: 1px; background-color: #ccc;margin:0" />
      <div v-if="detailStatus" style="display:flex;flex-wrap: wrap;margin-top:15px">
        <div v-if="dataDetail.orderInfo" style="width:50%;margin-bottom:15px"> 支付类型：{{Number(dataDetail.orderInfo.payType) === 0 ? '定金支付' : '订单支付'}}</div>
        <div v-else style="width:50%;margin-bottom:15px">{{dataDetail.subject}}</div>
        <div style="width:50%;margin-bottom:15px"> 支付渠道：{{dataDetail.payTool}}</div>
        <div style="width:50%;margin-bottom:15px"> 支付金额：{{dataDetail.totalAmount}}</div>
        <div style="width:50%;margin-bottom:15px"> 生成时间：{{dataDetail.createTime}}</div>
        <div style="width:50%;margin-bottom:15px"> 支付时间：{{dataDetail.payTime}}</div>
        <div style="width:50%;margin-bottom:15px"> 支付流水号：{{dataDetail.orderCode}}</div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs, ref } from 'vue'
import { tree, lineChart, area, ring } from './common/echarts'
import { conversionN, conversionS } from '@/utils/util'
import { PayListPage, statistics, department, pageDetail, sandPayExport } from '@/api/trans/finance/payList'
import { RedoOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
// import { DownOutlined } from '@ant-design/icons-vue'
export default {
  components: {
    RedoOutlined
  },
  setup () {
    const state = reactive({
      activeKey: ref('1'),
      optionsVal: 1,
      aaa: Math.round(10000 - (10000 - 5000)) * 0.01,
      departmentData: [],
      dataSource: [],
      search: {
        amt: '',
        startTime: '',
        endTime: '',
        orderCode: ''
      },
      dayData: {},
      weekData: {},
      monthData: {},
      dataDetail: {},
      reloadStatus: true,
      visible: false,
      loading: false,
      detailStatus: false,
      emphasis: {
        focus: 'series'
      },
      columns: [
        {
          title: '流水号',
          dataIndex: 'orderCode'
        },
        {
          title: '交易单号',
          dataIndex: 'bankserial'
        },
        {
          title: '收款金额',
          dataIndex: 'totalAmount'
        },
        {
          title: '支付时间',
          dataIndex: 'payTime'
        },
        {
          title: '结算方式',
          dataIndex: 'subject'
        },
        {
          title: '操作',
          dataIndex: 'edit',
          slots: {
            customRender: 'edit'
          }
        }
      ],
      pagination: {
        current: 1,
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      options: [
        {
          value: 1,
          label: '今天'
        },
        {
          value: 2,
          label: '本周'
        },
        {
          value: 3,
          label: '当月'
        },
        {
          value: 4,
          label: '今年'
        }
      ]
    })
    onMounted(() => {
      setTimeout(() => {
        loadData()
      }, 500)
    })
    const yearLoad = () => {
      department({ timeUnit: state.optionsVal })
        .then(res => {
          if (res.code === 10000) {
            // console.log(res)
            const yearData = { yearMount: '', data: [] }
            yearData.yearMount = res.data.annualIncome
            state.departmentData = res.data.departmentIncomeDetailDTOS
            res.data.departmentIncomeDetailDTOS.forEach(item => {
              yearData.data.push({
                value: item.totalAmount,
                name: item.orgName
              })
            })
            ring('year', yearData)
          }
        }).catch(err => console.log(err))
    }
    const reloadData = num => {
      switch (num) {
        case 1:
          if (state.reloadStatus === true) {
            state.dayData.totalAmount = 'loading...'
            statistics({ timeUnit: 1 }) // 日收益
              .then(res => {
                if (res.code === 10000) {
                  state.dayData.totalAmount = res.data.totalAmount
                  state.dayData.tradeCount = res.data.tradeCount
                  state.reloadStatus = false
                  setTimeout(() => state.reloadStatus = true, 3000)
                  const dayData = {
                    first: { xAxis: [], series: [] }
                  }
                  dayData.ringRatio = res.data.ringRatio
                  res.data.incomeStatisticsDetailDTOS.forEach(item => {
                    dayData.first.xAxis.push(Number(item.time))
                    dayData.first.series.push(Number(item.amount))
                  })
                  lineChart('day', dayData)
                }
              }).catch(err => console.log(err))
          } else message.error('点击太频繁了,请等一会吧')
          break

        case 2:
          if (state.reloadStatus === true) {
            state.weekData.totalAmount = 'loading...'
            statistics({ timeUnit: 2 }) // 周收益
              .then(res => {
                if (res.code === 10000) {
                  state.weekData.totalAmount = res.data.totalAmount
                  state.weekData.tradeCount = res.data.tradeCount
                  state.reloadStatus = false
                  setTimeout(() => state.reloadStatus = true, 3000)
                  const weekData = { xAxis: [], series: [] }
                  res.data.incomeStatisticsDetailDTOS.forEach(item => {
                    weekData.xAxis.push(item.time)
                    weekData.series.push(Number(item.amount))
                  })
                  tree('week', weekData)
                }
              }).catch(err => console.log(err))
          } else message.error('点击太频繁了,请等一会吧')
          break

        case 3:
          if (state.reloadStatus === true) {
            state.monthData.totalAmount = 'loading...'
            statistics({ timeUnit: 3 }) // 月收益
              .then(res => {
                if (res.code === 10000) {
                  state.monthData.totalAmount = res.data.totalAmount
                  state.monthData.tradeCount = res.data.tradeCount
                  state.reloadStatus = false
                  setTimeout(() => state.reloadStatus = true, 3000)
                  const monthData = { xAxis: [], series: [] }
                  monthData.ringRatio = res.data.ringRatio
                  res.data.incomeStatisticsDetailDTOS.forEach(item => {
                    monthData.xAxis.push(item.time + '月')
                    monthData.series.push(Number(item.amount))
                  })
                  area('months', monthData)
                }
              }).catch(err => console.log(err))
          } else message.error('点击太频繁了,请等一会吧')
          break
      }
    }
    const loadData = () => {
      state.loading = true
      const loadData = () => {
        reloadData(1)
        reloadData(2)
        reloadData(3)
      }
      yearLoad()
      loadData()
      pageList()
    }
    const pageList = (num) => {
      state.loading = true
      PayListPage({
        amt: state.search.amt,
        bankserial: state.search.bankserial,
        startTime: time(state.search.startTime),
        endTime: time(state.search.endTime),
        orderCode: state.search.orderCode,
        current: num === 1 ? 1 : state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          // console.log(res)
          state.dataSource = res.data.records
          state.dataSource.forEach(item => {
            if (item.payTool === '0101') item.payTool = '微信支付'
            else item.payTool = '扫码支付'
          })
          state.pagination.current = res.data.current
          state.pagination.total = res.data.total
          state.loading = false
        }
      }).catch(err => { console.log(err) })
    }
    // 查询筛选
    const searchTime = (e, v) => {
      // console.log(e, v)
      state.search.startTime = v[0]
      state.search.endTime = v[1]
    }
    const time = (v) => {
      v = v.replace(/:/g, '')
      v = v.replace(/-/g, '')
      v = v.replace(/ /g, '')
      return v
    }
    const handleChange = value => {
      state.optionsVal = value
      yearLoad()
    }
    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      pageList()
    }
    const look = records => {
      pageDetail('sandOrderCode=' + records.orderCode)
        .then(res => {
          if (res.code === 10000) {
            state.detailStatus = true
            res.data.payTool = res.data.payTool === '0101' ? '微信支付' : '扫码支付'
            res.data.payTime = res.data.payTime.split('T')[0] + ' ' + res.data.payTime.split('T')[1]
            res.data.createTime = res.data.createTime.split('T')[0] + ' ' + res.data.createTime.split('T')[1]
            state.dataDetail = res.data
          }
        }).catch(err => console.log(err))
    }
    const sandPayExportData = () => {
      sandPayExport({
        amt: state.search.amt,
        bankserial: state.search.bankserial,
        startTime: time(state.search.startTime),
        endTime: time(state.search.endTime),
        orderCode: state.search.orderCode
      }, '支付情况表').then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
        }
      }).catch(err => {
        console.log(err)
      })
    }
    return {
      ...toRefs(state),
      conversionN,
      conversionS,
      reloadData,
      look,
      pageList,
      searchTime,
      handleChange,
      handleTableChange,
      sandPayExportData
    }
  }
}
</script>
